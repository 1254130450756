import { AppLayoutComponent } from './framework/layout/app.layout.component';
import { AuthenticatedGuard } from './framework/guards/authenticated.guard';
import { UserProfileComponent } from './application/components/user-profile/user-profile.component';
import { ServiceComponent } from './application/components/service/service.component';
import { ArticlesComponent } from './application/components/articles/articles.component';
import { NotfoundComponent } from './framework/components/not-found/notfound.component';
import { DashboardComponent } from './application/components/dashboard/dashboard.component';
import { ArticleComponent } from './application/components/article/article.component';
import { SignupComponent } from './framework/components/auth/signup/signup.component';

export const APP_ROUTES = [
    {
        path: '',
        component: AppLayoutComponent,
        children: [
            {
                path: '',
                canActivate: [AuthenticatedGuard],
                component: DashboardComponent,
            },
            {
                path: 'profile',
                canActivate: [AuthenticatedGuard],
                component: UserProfileComponent,
            },
            {
                path: 'service',
                canActivate: [AuthenticatedGuard],
                component: ServiceComponent,
            },
            {
                path: 'articles',
                canActivate: [AuthenticatedGuard],
                component: ArticlesComponent,
            },
            {
                path: 'articles/:itemId',
                canActivate: [AuthenticatedGuard],
                component: ArticleComponent,
            },
        ],
    },
    { path: 'auth', loadChildren: () => import('./framework/components/auth/auth.routes').then((m) => m.AUTH_ROUTES) },
    { path: 'signup', component: SignupComponent },
    { path: 'notfound', component: NotfoundComponent },
    { path: '**', redirectTo: '/notfound' },
];
