import { Component } from '@angular/core';
import { BrandService } from '../../../application/services/brand.service';
import { TranslateService } from '@ngx-translate/core';
import { RouterLink } from '@angular/router';

@Component({
    selector: 'app-notfound',
    templateUrl: './notfound.component.html',
    standalone: true,
    imports: [RouterLink],
})
export class NotfoundComponent {
    constructor(
        private brandService: BrandService,
        private translateService: TranslateService,
    ) {}

    get brandLogoDark(): string {
        return this.brandService.getBrandLogo('dark');
    }

    get brandLogoAlt(): string {
        return this.translateService.instant('brandLogoAlt', this.brandService.brandNameString);
    }
}
