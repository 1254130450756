import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgClass } from '@angular/common';
import { Router, RouterLink } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { TranslateService, TranslateModule } from '@ngx-translate/core';

import { LayoutService } from './service/app.layout.service';
import { AuthenticationService } from '../services/authentication.service';
import { BrandService } from '../../application/services/brand.service';
import { HttpParams } from '@angular/common/http';
import { SettingsResponse } from 'src/common/models/umbraco-responses/SettingsResponse';
import { ContentService } from '../../application/services/content.service';
import { ContentProcessor } from '../../application/content.processor';
import { take } from 'rxjs';
import { SettingsModel } from '../../application/models/SettingsModel';
import { HelperService } from '../services/helper.service';

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html',
    standalone: true,
    imports: [RouterLink, NgClass, TranslateModule],
})
export class AppTopBarComponent implements OnInit {
    items!: MenuItem[];
    tieredItems: any[] = [];
    dropDownItems: MenuItem[] = [];
    languageMenuItems: { code: string; flagCode: string; name: string }[] = [
        {
            code: 'fi-FI',
            flagCode: 'fi',
            name: 'Suomi',
        },
        {
            code: 'en-US',
            flagCode: 'us',
            name: 'English',
        },
    ];
    settings: SettingsModel | undefined;

    @ViewChild('menuButton') menuButton!: ElementRef;

    @ViewChild('topBarMenuButton') topbarMenuButton!: ElementRef;

    @ViewChild('topBarMenu') menu!: ElementRef;

    constructor(
        public layoutService: LayoutService,
        private brandService: BrandService,
        private authenticationService: AuthenticationService,
        private translateService: TranslateService,
        private helperService: HelperService,
        private contentService: ContentService,
        public contentProcessor: ContentProcessor,
        public router: Router,
    ) {}

    ngOnInit() {
        this.dropDownItems = [
            {
                label: '',
                icon: 'pi fa-solid fa-table',
                items: [
                    {
                        label: this.translateService.instant('nav.editProfile'),
                        icon: 'pi fa-solid fa-user-pen',
                        routerLink: '/profile/edit',
                    },
                    {
                        label: this.translateService.instant('nav.passwordChange'),
                        icon: 'pi fa-solid fa-key',
                        routerLink: '/profile/password-change',
                    },
                ],
            },
        ];

        this.brandService.brandId$.subscribe({
            next: (brandId) => {
                const params = new HttpParams({
                    fromObject: {
                        filter: 'contentType:settings',
                        fields: 'properties[$all]',
                        fetch: `descendants:${brandId}`,
                    },
                });

                this.contentService
                    .getContentItemsFromQuery<{ total: number; items: SettingsResponse[] }>(
                        params,
                        this.helperService.currentLanguage,
                    )
                    .pipe(take(1))
                    .subscribe({
                        next: (result) => {
                            if (result.items.length > 0) {
                                this.settings = this.contentProcessor.getSettingsFromSettingsResponse(result.items[0]);
                            }
                        },
                    });
            },
        });
    }

    async logOut() {
        await this.authenticationService.logout();
    }

    changeLanguage(languageCode: string): void {
        this.translateService.use(languageCode);
    }

    async edit() {
        await this.router.navigate(['/admin/group']);
    }

    async changePassword() {
        await this.router.navigate(['/profile/password-change']);
    }

    goToManuals(): void {
        if (this.settings?.manualsPage) {
            const { url, target } = this.settings.manualsPage;
            window.open(url, target || '_self');
        }
    }

    goToOffers(): void {
        if (this.settings?.offersPage) {
            const { url, target } = this.settings.offersPage;
            window.open(url, target || '_self');
        }
    }

    get brandLogoDark(): string {
        return this.brandService.getBrandLogo('dark');
    }

    get brandLogoAlt(): string {
        return this.translateService.instant('brandLogoAlt', this.brandService.brandNameString);
    }

    get brandNameString(): string {
        return this.settings?.brandName || '';
    }
}
