import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { BrandService } from '../../services/brand.service';
import { PageHeaderModel } from '../../models/PageHeaderModel';
import { PageHeaderComponent } from '../page-header/page-header.component';
import { AsyncPipe, CommonModule, NgFor, NgIf } from '@angular/common';
import { CardModule } from 'primeng/card';
import { ListboxModule } from 'primeng/listbox';
import { ButtonModule } from 'primeng/button';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SelectFieldComponent } from '../../../../../frontend/app/framework/components/fields/select-field/select-field.component';
import { TextAreaFieldComponent } from '../../../../../frontend/app/framework/components/fields/text-area-field/text-area-field.component';
import { NumberFieldComponent } from '../../../../../frontend/app/framework/components/fields/number-field/number-field.component';
import { DateFieldComponent } from '../../../../../frontend/app/framework/components/fields/date-field/date-field.component';
import { TextFieldComponent } from '../../../../../frontend/app/framework/components/fields/text-field/text-field.component';
import { PhoneFieldComponent } from '../../../../../frontend/app/framework/components/fields/phone-field/phone-field.component';
import { EmailFieldComponent } from '../../../../../frontend/app/framework/components/fields/email-field/email-field.component';
import { PasswordFieldComponent } from '../../../../../frontend/app/framework/components/fields/password-field/password-field.component';
import { QuickLinkCardComponent } from '../quick-link-card/quick-link-card.component';
import { InputTextModule } from 'primeng/inputtext';
import { SwitchFieldComponent } from '../../../../../frontend/app/framework/components/fields/switch-field/switch-field.component';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { ContentService } from '../../services/content.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ContentProcessor } from '../../content.processor';
import { HttpParams } from '@angular/common/http';
import { PageResponse } from '../../../../../common/models/umbraco-responses/PageResponse';
import { catchError, map, Observable, Subject, switchMap, take, takeUntil, throwError } from 'rxjs';
import { MemberDetails } from '../../../../../common/models/MemberDetails';
import { MemberService } from '../../services/member.service';
import { ToastModule } from 'primeng/toast';
import { MyVehicleCardComponent } from '../dashboard/my-vehicle-card/my-vehicle-card.component';
import { CarDetails } from '../../../../../common/models/CarDetails';
import { MaintenanceService } from '../../services/maintenance.service';
import { ModelFilter } from '../../models/ModelFilter';
import { Dealer } from '../../models/Dealer';
import { DealerDetails } from '../../models/DealerDetails';
import { VehicleModel } from '../../models/user-profile-form/VehicleModel';
import { DealerAndCarModel } from '../../models/user-profile-form/DealerAndCarModel';
import { AutoCompleteFieldComponent } from 'src/frontend/app/framework/components/fields/autoComplete-field/autoComplete-field.component';
import { MAX_INT32 } from 'src/common/constants';
import { HelperService } from 'src/frontend/app/framework/services/helper.service';
import { DialogModule } from 'primeng/dialog';
import { MessageType } from '../../models/MessageType';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';

@Component({
    selector: 'app-user-profile',
    standalone: true,
    imports: [
        CommonModule,
        CardModule,
        ListboxModule,
        ButtonModule,
        AutoCompleteFieldComponent,
        FormsModule,
        ReactiveFormsModule,
        SelectFieldComponent,
        TextAreaFieldComponent,
        TextFieldComponent,
        NumberFieldComponent,
        DateFieldComponent,
        PhoneFieldComponent,
        EmailFieldComponent,
        PasswordFieldComponent,
        SwitchFieldComponent,
        PageHeaderComponent,
        QuickLinkCardComponent,
        AsyncPipe,
        NgIf,
        NgFor,
        RadioButtonModule,
        InputTextModule,
        TranslateModule,
        ToastModule,
        DialogModule,
        ConfirmDialogModule,
        MyVehicleCardComponent,
    ],
    templateUrl: './user-profile.component.html',
})
export class UserProfileComponent implements OnInit, OnDestroy {
    private destroy$ = new Subject<void>();

    showAddCarForm = false;
    newCar: { vinOrRegistrationNumber: string } = { vinOrRegistrationNumber: '' };
    memberDetails: MemberDetails | null = null;
    memberCars: CarDetails[] = [];
    userProfileForm!: FormGroup;
    carDetailsForms: FormGroup[] = [];
    ownershipTypes: string[] = [];
    dealers: Dealer[] = [];
    filteredDealers: Dealer[] = [];
    selectedDealer: Dealer | undefined;
    chosenServiceLocation!: DealerDetails;
    translatedOwnershipTypes: { label: string; id: string }[] = [];
    pageHeaderModel!: PageHeaderModel;

    constructor(
        private oidcSecurityService: OidcSecurityService,
        private brandService: BrandService,
        private contentService: ContentService,
        private translateService: TranslateService,
        private contentProcessor: ContentProcessor,
        private memberService: MemberService,
        private formBuilder: FormBuilder,
        private maintenanceService: MaintenanceService,
        private helperService: HelperService,
        private confirmationService: ConfirmationService,
    ) {}

    ngOnInit() {
        this.subscribeToBrandId();
        this.loadOwnershipTypes();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    get brandNameString(): string {
        return this.brandService.brandNameString;
    }

    searchDealers(query: string): void {
        const filters: ModelFilter = { serviceDealerName: query };
        this.maintenanceService
            .getDealers(this.brandNameString, 0, 90, filters)
            .pipe(takeUntil(this.destroy$), this.helperService.handleObservableError('Fetching dealers'))
            .subscribe((data: Dealer[]) => {
                this.dealers = data;
                this.filteredDealers = this.filterDealers(query);
            });
    }

    selectDealer(event: any): void {
        const dealerId = event.value.id;
        this.fetchDealerDetails(dealerId);
    }

    onSubmitCarDetails(): void {
        if (!this.chosenServiceLocation?.id || !this.chosenServiceLocation.displayName) {
            this.helperService.showMessage('error', MessageType.Error, 'Please Select a Valid Service Location');
            return;
        }

        if (this.carDetailsForms.some((carForm) => carForm.invalid)) {
            this.helperService.showMessage(
                'error',
                MessageType.Error,
                'Please Fill In All Required Car Details Correctly',
            );
            return;
        }

        const { memberKey, brandKey } = this.getMemberAndBrandKeys();
        const vehicles: VehicleModel[] = this.carDetailsForms.map((carForm: FormGroup) => ({
            registrationNumber: carForm.get('registrationNumber')?.value,
            ownershipType: carForm.get('ownership')?.value,
            annualUsage: carForm.get('annualMileage')?.value,
        }));

        const dealerAndCarModel: DealerAndCarModel = {
            memberKey,
            brandKey,
            dealerCode: this.chosenServiceLocation.id,
            dealerName: this.chosenServiceLocation.displayName,
            vehicles,
        };

        this.memberService
            .updateDealerAndCarDetails(dealerAndCarModel)
            .pipe(
                takeUntil(this.destroy$),
                catchError((err) => {
                    this.handleProfileUpdateResult(false, err);
                    return throwError(() => err);
                }),
            )
            .subscribe(() => this.handleProfileUpdateResult(true));
    }

    saveUserProfile(): void {
        if (this.userProfileForm.valid) {
            const formData = this.userProfileForm.value;
            const { memberCars, ...restFormData } = formData;

            const memberDetails: MemberDetails = {
                ...restFormData,
            };

            this.memberService
                .updateUserDetails(memberDetails)
                .pipe(
                    takeUntil(this.destroy$),
                    catchError((err) => {
                        this.handleProfileUpdateResult(false, err);
                        return throwError(() => err);
                    }),
                )
                .subscribe(() => this.handleProfileUpdateResult(true));
        } else {
            this.userProfileForm.markAllAsTouched();
        }
    }

    clearInput(input: HTMLInputElement): void {
        input.value = '';
    }

    getCarByRegistraionNumberOrVIN(): void {
        if (!this.newCar.vinOrRegistrationNumber) {
            this.helperService.showMessage('error', MessageType.Error, 'Please provide a VIN or Registration Number');
            return;
        }

        if (!this.memberDetails) {
            this.helperService.showMessage(
                'error',
                MessageType.Error,
                'Member details not found. Please try again later.',
            );
            return;
        }

        this.memberService
            .getCarByVinOrRegistrationNumber(this.memberDetails, this.newCar.vinOrRegistrationNumber)
            .pipe(
                takeUntil(this.destroy$),
                catchError((err) => {
                    this.handleError('Error fetching car details', err);
                    this.closeAddCarDialog();
                    return throwError(() => err);
                }),
            )
            .subscribe((car: CarDetails | null) => {
                if (car === null) {
                    this.helperService.showMessage('error', MessageType.Error, 'Car not found or does not exist');
                } else if (!car.isAlreadyAssigned) {
                    this.addCarToForm(car);
                    this.helperService.showMessage('success', MessageType.Success, 'Car found and added successfully');
                } else {
                    this.helperService.showMessage('error', MessageType.Error, 'The vehicle already has ownership');
                }
                this.closeAddCarDialog();
            });
    }

    addCarToForm(car: CarDetails): void {
        this.carDetailsForms.push(this.createCarFormGroup(car));
        this.memberCars.push(car);
    }

    removeCarOwnership(index: number): void {
        this.confirmationService.confirm({
            header: this.translateService.instant('Remove Ownership'),
            message: this.translateService.instant('Do you really want to remove your ownership of this car'),
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: this.translateService.instant('Confirm'),
            rejectLabel: this.translateService.instant('Cancel'),
            accept: () => {
                const carId = this.carDetailsForms[index].get('id')?.value;
                const { memberKey, brandKey } = this.getMemberAndBrandKeys();

                if (carId) {
                    this.memberService
                        .removeCarOwnership(memberKey, brandKey, carId)
                        .pipe(
                            takeUntil(this.destroy$),
                            this.helperService.handleObservableError('Removing car ownership'),
                        )
                        .subscribe(() => {
                            this.carDetailsForms.splice(index, 1);
                            this.memberCars = this.memberCars.filter((car) => car.id !== carId);
                            this.helperService.showMessage(
                                'success',
                                MessageType.Success,
                                'Car ownership removed successfully',
                            );
                        });
                } else {
                    this.helperService.showMessage('error', MessageType.Error, 'Could not find car ID to remove');
                }
            },
        });
    }

    private closeAddCarDialog(): void {
        this.showAddCarForm = false;
        this.newCar.vinOrRegistrationNumber = '';
    }

    private subscribeToBrandId(): void {
        this.brandService.brandId$
            .pipe(
                takeUntil(this.destroy$),
                switchMap((brandId) => {
                    if (brandId.length) {
                        this.loadPageHeader(brandId);
                        return this.loadMemberDetails(brandId);
                    } else {
                        return throwError(() => 'Brand ID is empty');
                    }
                }),
                this.helperService.handleObservableError('Subscribing to brand ID'),
            )
            .subscribe();
    }

    private loadPageHeader(brandId: string): void {
        const pageParams = new HttpParams({
            fromObject: {
                filter: 'contentType:userProfilePage',
                fields: 'properties[$all]',
                fetch: `descendants:${brandId}`,
            },
        });

        this.contentService
            .getContentItemsFromQuery<{ total: number; items: PageResponse[] }>(
                pageParams,
                this.helperService.currentLanguage,
            )
            .pipe(take(1), takeUntil(this.destroy$), this.helperService.handleObservableError('Loading page header'))
            .subscribe((results) => {
                this.pageHeaderModel = this.contentProcessor.getPageHeaderFromResult(results.items[0].properties);
            });
    }

    private loadMemberDetails(brandId: string): Observable<void> {
        return this.oidcSecurityService.userData$.pipe(
            switchMap((data) => {
                const userKey = data.userData.sub;
                return this.memberService
                    .getUserDetails(brandId, userKey)
                    .pipe(map((memberDetails) => ({ memberDetails, userKey })));
            }),
            takeUntil(this.destroy$),
            this.helperService.handleObservableError('Loading member details'),
            switchMap(({ memberDetails, userKey }) => {
                this.memberDetails = memberDetails;

                if (this.memberDetails) {
                    this.memberDetails.key = userKey;
                    this.memberDetails.brandKey = brandId;
                    this.memberDetails.language = this.helperService.currentLanguage;
                    this.memberDetails.brand = this.brandNameString;
                    this.memberCars = this.memberDetails.memberCars || [];
                    this.userProfileForm = this.formBuilder.group(this.memberDetails);
                    this.populateCarDetailsForms(this.memberCars);
                    this.setChosenServiceLocation();
                }
                return [];
            }),
        );
    }

    private populateCarDetailsForms(cars: CarDetails[]): void {
        this.carDetailsForms = cars.map((car) => this.createCarFormGroup(car));
    }

    private getMemberAndBrandKeys(): { memberKey: string; brandKey: string } {
        const memberKey = this.userProfileForm.get('key')?.value;
        const brandKey = this.userProfileForm.get('brandKey')?.value;
        return { memberKey, brandKey };
    }

    private loadOwnershipTypes(): void {
        this.memberService
            .getOwnershipTypes()
            .pipe(takeUntil(this.destroy$), this.helperService.handleObservableError('Loading ownership types'))
            .subscribe((types) => {
                this.ownershipTypes = types;
                this.loadTranslations();
            });
    }

    private loadTranslations(): void {
        const isoCode = this.translateService.currentLang || this.translateService.defaultLang;
        this.translateService
            .getTranslation(isoCode)
            .pipe(takeUntil(this.destroy$), this.helperService.handleObservableError('Loading translations'))
            .subscribe((translations) => {
                this.translatedOwnershipTypes = this.mapToLabelId(this.ownershipTypes, 'Ownership Type', translations);
            });
    }

    private mapToLabelId(
        items: string[],
        translationPrefix: string,
        translations: any,
    ): { label: string; id: string }[] {
        return items.map((item) => ({
            label: translations[`${translationPrefix}.${item}`] || item,
            id: item,
        }));
    }

    private filterDealers(query: string): Dealer[] {
        return this.dealers.filter((dealer) => {
            let searchField = dealer.label ?? dealer.dealerName ?? '';

            dealer.displayName = searchField;
            return searchField.toLowerCase().includes(query.toLowerCase());
        });
    }

    private setChosenServiceLocation(): void {
        if (this.memberCars.length) {
            const dealerCode = this.memberCars[0].dealerCode;
            if (dealerCode) {
                this.fetchDealerDetails(dealerCode);
            }
        }
    }

    private fetchDealerDetails(dealerId: number): void {
        this.maintenanceService
            .getDealerDetails(this.brandNameString, dealerId)
            .pipe(takeUntil(this.destroy$), this.helperService.handleObservableError('Fetching dealer details'))
            .subscribe((data: DealerDetails) => {
                this.chosenServiceLocation = data;
                const searchField = this.chosenServiceLocation.label ?? this.chosenServiceLocation.dealerName;
                this.chosenServiceLocation.displayName = searchField;
            });
    }

    private createCarFormGroup(car: CarDetails): FormGroup {
        return this.formBuilder.group({
            id: [{ value: car.id, disabled: true }],
            registrationNumber: [{ value: car.registrationNumber, disabled: true }],
            vin: [{ value: car.vin, disabled: true}],
            firstRegistrationDate: [{ value: this.helperService.formatDate(car.firstRegistrationDate), disabled: true}],
            modelSerie: [{ value: car.modelSerie, disabled: true}],
            model: [{ value: car.model, disabled: true }],
            mileage: [{ value: car.mileage, disabled: true }],
            ownership: [car.ownershipType, Validators.required],
            annualMileage: [car.yearlyMileage, [Validators.required, Validators.max(MAX_INT32)]],
        });
    }

    private handleProfileUpdateResult(success: boolean, error?: any): void {
        if (success) {
            this.helperService.showMessage('success', MessageType.Success, 'User Profile Saved');
        } else {
            this.helperService.showMessage('error', MessageType.Error, 'Saving User Profile Failed');
            if (error) {
                this.handleError('Error submitting data', error);
            }
        }
    }

    private handleError(message: string, error: any): void {
        console.error(message, error);
    }
}
