<div class="field">
    <label [htmlFor]="name" [id]="name">{{ getFieldLabel() }}</label>
    <input
        pInputText
        [attr.aria-label]="name"
        type="text"
        [readonly]="readonly"
        [style]="{ height: '40px', width: width ? 'width: ' + width + 'px;' : '100%', 'background-color': readonly ? '#e0e0e0' : 'transparent' }"
        [formControl]="formControl"
        [placeholder]="placeholder"
    />
    <app-validation-errors [control]="formControl"></app-validation-errors>
</div>
