import { Component, OnInit } from '@angular/core';
import { NewsEntry } from '../../models/newsEntryModel';
import { BrandService } from '../../services/brand.service';
import { HttpParams } from '@angular/common/http';
import { ContentService } from '../../services/content.service';
import { take } from 'rxjs/operators';
import { TranslateModule } from '@ngx-translate/core';
import { NgClass, NgFor, LowerCasePipe, DatePipe } from '@angular/common';
import { ArticleResponse } from '../../../../../common/models/umbraco-responses/ArticleResponse';
import { ContentProcessor } from '../../../../../frontend/app/application/content.processor';
import { LATEST_NEWS_URL } from '../../../../../common/constants';
import { HelperService } from 'src/frontend/app/framework/services/helper.service';

@Component({
    selector: 'app-latest-news',
    templateUrl: './latest-news.component.html',
    standalone: true,
    imports: [NgClass, NgFor, LowerCasePipe, DatePipe, TranslateModule],
})
export class LatestNewsComponent implements OnInit {
    latestNewsUrl: string = LATEST_NEWS_URL;
    newsEntries: NewsEntry[] = [];
    brandId: string = '';

    constructor(
        public brandService: BrandService,
        private contentService: ContentService,
        private helperService: HelperService,
        public contentProcessor: ContentProcessor,
    ) {}

    get brandNameString(): string {
        return this.brandService.brandNameString;
    }

    ngOnInit() {
        this.brandService.brandId$.subscribe({
            next: (brandId) => {
                if (brandId.length) {
                    const params = new HttpParams({
                        fromObject: {
                            filter: 'contentType:article',
                            fields: 'properties[$all]',
                            take: 4,
                            fetch: `descendants:${brandId}`,
                        },
                    });

                    this.contentService
                        .getContentItemsFromQuery<{ total: number; items: ArticleResponse[] }>(
                            params,
                            this.helperService.currentLanguage,
                        )
                        .pipe(take(1))
                        .subscribe({
                            next: (results) => {
                                this.newsEntries = this.contentProcessor.getNewsEntriesFromArticleResponse(
                                    results.items,
                                );
                            },
                        });
                }
            },
        });
    }
}
