import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardModule } from 'primeng/card';
import { ImageModule } from 'primeng/image';
import { BrandService } from '../../../../../frontend/app/application/services/brand.service';
import { BookServiceFormComponent } from './book-service-form/book-service-form.component';
import { TranslateModule } from '@ngx-translate/core';
import { ContentService } from '../../services/content.service';
import { ContentProcessor } from '../../content.processor';
import { PageHeaderComponent } from '../page-header/page-header.component';
import { HttpParams } from '@angular/common/http';
import { PageResponse } from '../../../../../common/models/umbraco-responses/PageResponse';
import { take } from 'rxjs';
import { PageHeaderModel } from '../../models/PageHeaderModel';
import { MemberInfoComponent } from '../member-info/member-info.component';
import { HelperService } from 'src/frontend/app/framework/services/helper.service';

@Component({
    standalone: true,
    imports: [
        CommonModule,
        CardModule,
        MemberInfoComponent,
        BookServiceFormComponent,
        ImageModule,
        TranslateModule,
        PageHeaderComponent,
    ],
    selector: 'app-book-service',
    templateUrl: './book-service.component.html',
})
export class BookServiceComponent implements OnInit {
    pageHeaderModel!: PageHeaderModel;

    constructor(
        private brandService: BrandService,
        private contentService: ContentService,
        private helperService: HelperService,
        public contentProcessor: ContentProcessor,
    ) {}

    ngOnInit() {
        this.brandService.brandId$.subscribe({
            next: (brandId) => {
                if (brandId.length) {
                    const pageParams = new HttpParams({
                        fromObject: {
                            filter: 'contentType:service',
                            fields: 'properties[$all]',
                            fetch: `descendants:${brandId}`,
                        },
                    });

                    this.contentService
                        .getContentItemsFromQuery<{ total: number; items: PageResponse[] }>(
                            pageParams,
                            this.helperService.currentLanguage,
                        )
                        .pipe(take(1))
                        .subscribe({
                            next: (results) => {
                                this.pageHeaderModel = this.contentProcessor.getPageHeaderFromResult(
                                    results.items[0].properties,
                                );
                            },
                        });
                }
            },
        });
    }

    get brandNameString(): string {
        return this.brandService.brandNameString;
    }

    get bannerPhoto(): string {
        return this.brandService.bannerPhoto;
    }

    get brandName(): { brandName: string } {
        return this.brandService.brandName;
    }
}
