import { Injectable } from '@angular/core';
import { environment } from 'src/frontend/environment/environment';

@Injectable({
  providedIn: 'root',
})
export class BrandUrlService {
  get brandNameString(): string {
    if (window.location.hostname.indexOf('dacia') > -1) {
      return 'Dacia';
    } else if (window.location.hostname.indexOf('renault') > -1) {
      return 'Renault';
    } else {
      return 'Hyundai';
    }
  }

  get brandBackendUrl(): string {
    const brand = this.brandNameString as 'Dacia' | 'Renault' | 'Hyundai';
    const backendUrls = environment.apiUrls;

    if (backendUrls[brand]) {
      return backendUrls[brand];
    } else {
      throw new Error(`No backend URL found for the brand: ${brand}`);
    }
  }
}