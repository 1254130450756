import { Component, Input } from '@angular/core';
import { PageHeaderModel } from '../../models/PageHeaderModel';
import { CommonModule } from '@angular/common';
import { ImageModule } from 'primeng/image';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-page-header',
    standalone: true,
    imports: [CommonModule, ImageModule, TranslateModule],
    templateUrl: './page-header.component.html',
})
export class PageHeaderComponent {
    @Input({ required: true }) pageHeaderModel!: PageHeaderModel;
}
