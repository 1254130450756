import { Component, Input, OnInit } from '@angular/core';
import { ContentService } from '../../services/content.service';
import { take } from 'rxjs/operators';
import { CommonModule } from '@angular/common';
import { ArticleModel } from '../../models/articleModel';
import { ArticleResponse } from '../../../../../common/models/umbraco-responses/ArticleResponse';
import { ContentProcessor } from '../../content.processor';
import { BrandService } from '../../services/brand.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-article',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './article.component.html',
})
export class ArticleComponent implements OnInit {
    @Input() itemId = '';
    article: ArticleModel | undefined;

    constructor(
        private contentService: ContentService,
        private brandService: BrandService,
        public contentProcessor: ContentProcessor,
        private translateService: TranslateService,
    ) {}

    get brandNameString(): string {
        return this.brandService.brandNameString;
    }

    ngOnInit() {
        this.contentService
            .getContentItemByRoute<ArticleResponse>(this.itemId,  this.translateService.currentLang || this.translateService.defaultLang)
            .pipe(take(1))
            .subscribe({
                next: (result) => {
                    this.article = this.contentProcessor.getArticleFromArticleResponse(result);
                },
            });
    }
}
