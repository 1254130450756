<p-confirmDialog></p-confirmDialog>
<div class="grid">
    <div class="col-12">
        <app-page-header *ngIf="pageHeaderModel" [pageHeaderModel]="pageHeaderModel!"></app-page-header>
    </div>

    <div *ngIf="userProfileForm" class="text-sm col-12">
        <div class="grid">
            <div class="md:col-6 col-12">
                <p-card>
                    <ng-template pTemplate="header">
                        <div
                            [ngClass]="[
                                (brandNameString | lowercase) + '-background-light-color',
                                (brandNameString | lowercase) + '-border-color'
                            ]"
                            class="border-bottom-2"
                        >
                            <h5 [ngClass]="(brandNameString | lowercase) + '-text-color'" class="m-0">
                                {{ 'User Details' | translate }}
                            </h5>
                        </div>
                    </ng-template>

                    <form [formGroup]="userProfileForm">
                        <div class="mb-3">
                            <app-text-field
                                name="firstName"
                                formControlName="firstName"
                                [required]="true"
                            ></app-text-field>
                        </div>

                        <div class="mb-3">
                            <app-text-field
                                name="lastName"
                                formControlName="lastName"
                                [required]="true"
                            ></app-text-field>
                        </div>

                        <div class="mb-3">
                            <app-text-field name="streetAddress" formControlName="streetAddress"></app-text-field>
                        </div>

                        <div class="grid mb-3">
                            <div class="md:col-6 col-12">
                                <app-text-field name="zipCode" formControlName="zipCode"></app-text-field>
                            </div>

                            <div class="md:col-6 col-12">
                                <app-text-field name="city" formControlName="city"></app-text-field>
                            </div>
                        </div>

                        <div class="grid mb-3">
                            <div class="md:col-6 col-12">
                                <app-phone-field name="phoneNumber" formControlName="phoneNumber"></app-phone-field>
                            </div>

                            <div class="md:col-6 col-12">
                                <app-email-field name="email" formControlName="email" readonly="true">
                                </app-email-field>
                            </div>
                        </div>

                        <app-switch-field
                            name="acceptedMarketing"
                            readonly="false"
                            formControlName="acceptedMarketing"
                            [labelTop]="false"
                        />

                        <div
                            *ngIf="!userProfileForm.value.acceptedMarketing"
                            class="flex-initial flex align-items-center justify-content-left border-3 border-red-500"
                        >
                            <div
                                class="flex-initial flex align-items-center justify-content-center w-3rem h-5rem bg-red-500"
                            >
                                <i class="pi pi-circle-exclamation" style="font-size: 2rem; color: white"></i>
                            </div>
                            <p class="p-3">
                                {{ 'Consent Warning' | translate }}
                            </p>
                        </div>

                        <div class="my-3">
                            <p-button
                                name="userProfileSendRequest"
                                label="{{ 'Save' | translate }}"
                                icon="pi pi-save"
                                (onClick)="saveUserProfile()"
                            />
                        </div>
                    </form>
                </p-card>
            </div>
            <div class="md:col-6 col-12">
                <p-card>
                    <ng-template pTemplate="header">
                        <div
                            [ngClass]="[
                                (brandNameString | lowercase) + '-background-light-color',
                                (brandNameString | lowercase) + '-border-color'
                            ]"
                            class="border-bottom-2"
                        >
                            <h5 [ngClass]="(brandNameString | lowercase) + '-text-color'" class="m-0">
                                {{ 'My Preferred Service Point' | translate }}
                            </h5>
                        </div>
                    </ng-template>

                    <div class="mb-3">
                        <span class="p-input-icon-left w-12">
                            <p>{{ 'Select a Service Point' | translate }}</p>

                            <app-auto-complete-field
                                [style]="{ height: '40px', width: '100%' }"
                                [(ngModel)]="selectedDealer"
                                [suggestions]="filteredDealers"
                                (completeMethod)="searchDealers($event.query)"
                                (onSelect)="selectDealer($event)"
                                field="displayName"
                                placeholder="{{ 'Search by city or name' | translate }}"
                            >
                            </app-auto-complete-field>
                        </span>
                    </div>

                    <div *ngIf="chosenServiceLocation">
                        <h6>{{ chosenServiceLocation.displayName }}</h6>
                        <p>
                            {{ chosenServiceLocation.streetAddress }}<br />
                            {{ chosenServiceLocation.postalCode }}<br />
                            {{ chosenServiceLocation.city }}<br />
                            {{ chosenServiceLocation.phone }}<br />
                            {{ chosenServiceLocation.email }}
                        </p>
                    </div>
                </p-card>
                <div class="pb-4"></div>
                <form (ngSubmit)="onSubmitCarDetails()">
                    <ng-container *ngFor="let carForm of carDetailsForms; let i = index">
                        <div [formGroup]="carForm">
                            <p-card>
                                <ng-template pTemplate="header">
                                    <div
                                        [ngClass]="[
                                            (brandNameString | lowercase) + '-background-light-color',
                                            (brandNameString | lowercase) + '-border-color'
                                        ]"
                                        class="border-bottom-2"
                                    >
                                        <h5 [ngClass]="(brandNameString | lowercase) + '-text-color'" class="m-0">
                                            {{ 'Car Information' | translate }}
                                        </h5>
                                    </div>
                                </ng-template>

                                <div class="grid mb-3">
                                    <div class="md:col-6 col-12">
                                        <app-text-field
                                            name="registrationNumber"
                                            formControlName="registrationNumber"
                                            readonly="readonly"
                                            [disabled]="true"
                                        ></app-text-field>
                                    </div>

                                    <div class="md:col-6 col-12">
                                        <app-text-field
                                            name="vin"
                                            formControlName="vin"
                                            readonly="readonly"
                                            [disabled]="true"
                                        ></app-text-field>
                                    </div>
                                </div>

                                <div class="grid mb-3">
                                    <div class="md:col-6 col-12">
                                        <app-text-field
                                            name="firstRegistrationDate"
                                            formControlName="firstRegistrationDate"
                                            readonly="readonly"
                                            [disabled]="true"
                                        ></app-text-field>
                                    </div>

                                    <div class="md:col-6 col-12">
                                        <app-text-field
                                            name="modelSerie"
                                            formControlName="modelSerie"
                                            readonly="readonly"
                                            [disabled]="true"
                                        ></app-text-field>
                                    </div>
                                </div>

                                <div class="grid mb-3">

                                    <div class="md:col-6 col-12">
                                        <app-text-field
                                            name="model"
                                            formControlName="model"
                                            readonly="readonly"
                                            [disabled]="true"
                                        ></app-text-field>
                                    </div>
                                    <div class="md:col-6 col-12">
                                        <app-text-field
                                            name="mileage"
                                            formControlName="mileage"
                                            readonly="readonly"
                                            [disabled]="true"
                                        ></app-text-field>
                                    </div>
                                </div>

                                <div class="grid mb-3">
                                    <div class="md:col-6 col-12">
                                        <app-select-field
                                            name="ownership"
                                            formControlName="ownership"
                                            [options]="translatedOwnershipTypes"
                                            [required]="true"
                                        >
                                        </app-select-field>
                                    </div>

                                    <div class="md:col-6 col-12">
                                        <app-number-field
                                            name="annualMileage"
                                            formControlName="annualMileage"
                                            [required]="true"
                                        ></app-number-field>
                                    </div>
                                </div>
                            </p-card>
                        </div>
                        <div class="my-3">
                            <p-button
                                name="updateCarServiceSendRequest"
                                class="mr-1"
                                label="{{ 'Remove' | translate }}"
                                icon="pi pi-minus"
                                (onClick)="removeCarOwnership(i)"
                            />
                        </div>
                    </ng-container>
                    <div class="my-3">
                        <p-button
                            name="updateCarServiceSendRequest"
                            class="mr-1"
                            label="{{ 'Save' | translate }}"
                            icon="pi pi-save"
                            (onClick)="onSubmitCarDetails()"
                        />
                        <p-button
                            name="updateCarServiceSendRequest"
                            label="{{ 'Add New Car' | translate }}"
                            icon="pi pi-plus"
                            (onClick)="showAddCarForm = true"
                        />
                    </div>
                </form>
            </div>
            <p-dialog
                header="{{ 'Add New Car' | translate }}"
                [(visible)]="showAddCarForm"
                [modal]="true"
                [closable]="false"
            >
                <div class="grid">
                    <div class="col-12">
                        <label for="vinOrReg">{{ 'VIN or Registration Number' | translate }}: </label>
                        <input
                            type="text"
                            pInputText
                            [(ngModel)]="newCar.vinOrRegistrationNumber"
                            id="vinOrReg"
                            placeholder="{{ 'Enter VIN or Registration Number' | translate }}"
                        />
                    </div>
                    <div class="col-12 mt-3">
                        <p-button
                            name="checkCar"
                            label="{{ 'Check Car' | translate }}"
                            icon="pi pi-search"
                            (onClick)="getCarByRegistraionNumberOrVIN()"
                        ></p-button>
                        <p-button
                            name="closeDialog"
                            label="{{ 'Cancel' | translate }}"
                            icon="pi pi-times"
                            class="ml-2"
                            (onClick)="showAddCarForm = false"
                        ></p-button>
                    </div>
                </div>
            </p-dialog>
        </div>
    </div>
    <p-toast />
</div>
