import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ContentService } from './content.service';
import { ModelFilter } from '../models/ModelFilter';
import { Dealer } from '../models/Dealer';
import { DealerDetails } from '../models/DealerDetails';
import { HttpWrapperService } from './http-wrapper.service';
import { BookServiceRequest } from 'src/common/models/api/bookServiceRequest';

@Injectable({
    providedIn: 'root',
})

export class MaintenanceService {
    constructor(
        private httpWrapper: HttpWrapperService,
        private contentService: ContentService,
    ) {}

    sendServiceRequest(bookServiceRequest: BookServiceRequest): Observable<BookServiceRequest> {
      const url = `${this.contentService.url}umbraco/api/MaintenanceApi/SendServiceRequest`;
      return this.httpWrapper.post<BookServiceRequest>(url, bookServiceRequest);
    }

    getDealerDetails(brand: string, dealerId: number): Observable<DealerDetails> {
      const params = new HttpParams()
      .set('brand', brand)
      .set('dealerId', dealerId.toString());

    const url = `${this.contentService.url}umbraco/api/MaintenanceApi/Dealer`;

    return this.httpWrapper.get<DealerDetails>(url, params);
    }

    getDealers(brand: string, skip: number = 0, limit: number = 90, filters?: ModelFilter): Observable<Dealer[]> {
      let params = new HttpParams()
      .set('brand', brand)
      .set('skip', skip.toString())
      .set('limit', limit.toString());

    if (filters) {
      Object.keys(filters).forEach(key => {
        const value = filters[key as keyof ModelFilter];
        if (value) {
          params = params.append(key, value);
        }
      });
    }

    const url = `${this.contentService.url}umbraco/api/MaintenanceApi/Dealers`;

    return this.httpWrapper.get<Dealer[]>(url, params);
    }
}
