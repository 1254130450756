import { OnDestroy, OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { LayoutService } from './service/app.layout.service';
import { MenuItem } from 'primeng/api';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Subject } from 'rxjs';
import { AppMenuitemComponent } from './app.menuitem.component';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html',
    standalone: true,
    imports: [NgIf, NgFor, AppMenuitemComponent, AsyncPipe],
})
export class AppMenuComponent implements OnInit, OnDestroy {
    menuItems: MenuItem[] = [];

    private onDestroy = new Subject<void>();

    constructor(
        public layoutService: LayoutService,
        public oidcSecurityService: OidcSecurityService,
    ) {}

    ngOnInit() {
        this.oidcSecurityService.isAuthenticated$.subscribe({
            next: ({ isAuthenticated }) => {
                this.resetMenuItems();

                if (!isAuthenticated) {
                    return;
                }

                this.menuItems.push({
                    label: 'nav.settings',
                    items: [
                        { label: 'nav.users', icon: 'fa-solid fa-user', routerLink: ['/admin/user'] },
                        {
                            label: 'nav.groups',
                            icon: 'fa-solid fa-user-group',
                            routerLink: ['/admin/group'],
                        },
                        { label: 'nav.roles', icon: 'fa-solid fa-brands fa-redhat', routerLink: ['/admin/role'] },
                        { label: 'nav.assets', icon: 'fa-solid fa-image', routerLink: ['/admin/asset'] },
                        { label: 'nav.jobs', icon: 'fa-solid fa-briefcase', routerLink: ['/admin/job'] },
                        {
                            label: 'nav.scheduledTasks',
                            icon: 'fa-solid fa-list-check',
                            routerLink: ['/admin/scheduled-task'],
                        },
                        { label: 'nav.nodes', icon: 'fa-regular fa-circle-dot', routerLink: ['/admin/node'] },
                        {
                            label: 'nav.options',
                            icon: 'fa-sharp fa-solid fa-list-ol',
                            routerLink: ['/admin/option'],
                        },
                    ],
                });
            },
        });
    }

    ngOnDestroy() {
        this.onDestroy.next();
        this.onDestroy.complete();
    }

    resetMenuItems(): void {
        this.menuItems = [
            {
                label: 'nav.home',
                items: [{ label: 'nav.dashboard', icon: 'fa-solid fa-house', routerLink: ['/'] }],
            },
        ];
    }
}
