<div class="relative" *ngIf="carouselProducts.length">
    <div>
        <p-carousel
            [showNavigators]="false"
            [value]="carouselProducts"
            [numVisible]="1"
            [numScroll]="1"
            [circular]="carouselAutorotate"
            [autoplayInterval]="carouselAutorotate ? 5000 : 0"
            indicatorsContentClass="indicatorsCardContentClass"
            itemStyle="height: 500px;"
        >
            <ng-template let-product pTemplate="item">
                <div
                    class="relative"
                    (click)="product.link ? redirectToUrl(product.link.url, product.link.target) : null"
                >
                    <img src="{{ product.image.url }}" [alt]="product.name" class="w-full" />

                    <div class="p-4 absolute" [ngStyle]="{ 'left.px': 5, 'top.px': 5 }">
                        <div class="text-3xl text-white font-medium mb-4">{{ product.title }}</div>
                        <div class="text-base text-white font-normal">{{ product.description }}</div>
                    </div>
                </div>
            </ng-template>
        </p-carousel>
    </div>
</div>
