<div class="grid">
    <div class="col-12">
        <h1 class="text-3xl text-primary uppercase">{{ pageHeaderModel.title | translate }}</h1>
    </div>
    <div class="col-12" style="height: 20vh" *ngIf="pageHeaderModel.imageUrl">
        <img
            class="w-full"
            style="object-fit: cover; height: 20vh"
            [src]="pageHeaderModel.imageUrl"
            alt="{{ pageHeaderModel.imageAlt ? pageHeaderModel.imageAlt : (pageHeaderModel.title | translate) }}"
        />
    </div>
    <div class="col-12 mt-5 mb-3" *ngIf="pageHeaderModel.description">
        <p class="text-lg">{{ pageHeaderModel.description! | translate }}</p>
    </div>
</div>
