import { Component, OnInit } from '@angular/core';
import { MenuItem, SharedModule } from 'primeng/api';
import { ContentService } from '../../services/content.service';
import { BrandService } from '../../services/brand.service';
import { take } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { ProductCarouselComponent } from '../product-carousel/product-carousel.component';
import { DividerModule } from 'primeng/divider';
import { RouterLink } from '@angular/router';
import { LatestNewsComponent } from '../latest-news/latest-news.component';
import { QuickLinkCardComponent } from '../quick-link-card/quick-link-card.component';
import { ButtonModule } from 'primeng/button';
import { AccordionModule } from 'primeng/accordion';
import { MyVehicleCardComponent } from './my-vehicle-card/my-vehicle-card.component';
import { PageHeaderComponent } from '../page-header/page-header.component';
import { NgIf, NgFor, AsyncPipe, JsonPipe } from '@angular/common';
import { DashboardResponse } from '../../../../../common/models/umbraco-responses/DashboardResponse';
import { ContentProcessor } from '../../../../../frontend/app/application/content.processor';
import { DashboardModel } from '../../models/dashboardModel';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { MemberService } from '../../services/member.service';
import { CarDetails } from '../../../../../common/models/CarDetails';
import { HelperService } from 'src/frontend/app/framework/services/helper.service';

@Component({
    templateUrl: './dashboard.component.html',
    standalone: true,
    imports: [
        NgIf,
        PageHeaderComponent,
        MyVehicleCardComponent,
        AccordionModule,
        SharedModule,
        ButtonModule,
        NgFor,
        QuickLinkCardComponent,
        LatestNewsComponent,
        RouterLink,
        DividerModule,
        ProductCarouselComponent,
        AsyncPipe,
        JsonPipe,
        TranslateModule,
    ],
})
export class DashboardComponent implements OnInit {
    items!: MenuItem[];
    car: any;
    brandSettings$ = this.brandService.brandSettings$;
    dashboard: DashboardModel | undefined;
    memberCars: CarDetails[] | null = null;
    constructor(
        private contentService: ContentService,
        private brandService: BrandService,
        private helperService: HelperService,
        private contentProcessor: ContentProcessor,
        private oidcSecurityService: OidcSecurityService,
        private memberService: MemberService,
    ) {}

    ngOnInit() {
        this.brandService.brandId$.subscribe({
            next: (brandId) => {
                if (brandId.length) {
                    const params = new HttpParams({
                        fromObject: {
                            filter: 'contentType:dashboard',
                            fields: 'properties[$all]',
                            fetch: `descendants:${brandId}`,
                        },
                    });

                    this.contentService
                        .getContentItemsFromQuery<{ total: number; items: DashboardResponse[] }>(
                            params,
                            this.helperService.currentLanguage,
                        )
                        .pipe(take(1))
                        .subscribe({
                            next: (result) => {
                                this.dashboard = this.contentProcessor.processDashboardContent(result.items[0]);
                            },
                        });

                    this.oidcSecurityService.userData$.subscribe({
                        next: (data) => {
                            const userKey = data.userData.sub;
                            this.memberService.getUserDetails(brandId, userKey).subscribe({
                                next: (data) => {
                                    if (data?.memberCars && data?.memberCars.length) {
                                        this.memberCars = data.memberCars;
                                    }
                                },
                            });
                        },
                    });
                }
            },
        });
    }

    fetchServiceEbook(vin: string) {
        this.contentService.getServiceBookPdf(vin).subscribe((response) => {
            const blob = new Blob([response], { type: 'application/pdf' });
            // saveAs(blob, `ServiceBook_${vin}.pdf`); -> install file-saver
        });
    }

    get isHyundaiBrand(): boolean {
        return this.brandService.brandNameString === 'Hyundai';
    }
}
