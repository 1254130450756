<div class="layout-top-bar justify-content-between xl:justify-content-center">
    <a class="layout-top-bar-logo col-2" routerLink="">
        <img [src]="brandLogoDark" [alt]="brandLogoAlt" class="w-8rem xl:w-10rem" alt="logo" width="100%" />
        <div class="text-2xl text-primary ml-3 font-bold">{{ brandNameString }}</div>
    </a>

    <!--        <button #menuButton class="p-link layout-menu-button layout-top-bar-button" (click)="layoutService.onMenuToggle()">-->
    <!--            <i class="pi fa-solid fa-bars"></i>-->
    <!--        </button>-->

    <button
        #topBarMenuButton
        class="p-link layout-top-bar-menu-button layout-top-bar-button col-1"
        (click)="layoutService.showProfileSidebar()"
    >
        <i class="pi fa-solid fa-bars"></i>
    </button>

    <div
        #topBarMenu
        class="layout-top-bar-menu col-12 md:col-9 ml-1 justify-content-evenly xl:justify-content-around align-content-center"
        [ngClass]="{ 'layout-top-bar-menu-mobile-active': layoutService.state.profileSidebarVisible }"
    >
        <div class="flex flex-column lg:flex-row col-12 lg:col-9 justify-content-evenly font-semibold gap-1 lg:gap-2">
            <button class="p-link mx-2 p-1 layout-top-bar-button w-full p-4 lg:p-1" [routerLink]="['service']">
                {{ 'nav.service' | translate }}
            </button>
            <button class="p-link mx-2 p-1 layout-top-bar-button w-full p-4 lg:p-1" (click)="goToManuals()">
                {{ 'nav.manuals' | translate }}
            </button>
            <button class="p-link mx-2 p-1 layout-top-bar-button w-full p-4 lg:p-1" [routerLink]="['articles']">
                {{ 'nav.articles' | translate }}
            </button>
            <button class="p-link mx-2 p-1 layout-top-bar-button w-full p-4 lg:p-1" (click)="goToOffers()">
                {{ 'nav.offers' | translate }}
            </button>
        </div>

        <div
            class="flex flex-column md:flex-row col-12 lg:col-3 align-items-start md:align-items-center align-content-center justify-content-evenly xl:justify-content-end gap-1 lg:gap-2"
        >
            <button
                type="button"
                class="p-link mx-2 p-1 layout-top-bar-button w-full p-4 lg:p-1"
                [routerLink]="['profile']"
            >
                <div class="flex align-items-center text-primary">
                    <i class="menu-icon pi fa-regular fa-circle-user text-primary"></i>
                    <div class="flex-grow-1 ml-1 block lg:hidden xl:block">{{ 'nav.profile' | translate }}</div>
                </div>
            </button>

            <!--            <p-dropdown-->
            <!--                [options]="languageMenuItems"-->
            <!--                (onChange)="changeLanguage($event.value)"-->
            <!--                optionValue="code"-->
            <!--                optionLabel="name"-->
            <!--            >-->
            <!--                <ng-template pTemplate="selectedItem">-->
            <!--                    <div class="country-item country-item-value flex align-items-center">-->
            <!--                        <i class="menu-icon pi fa-solid fa-earth-europe"></i>-->
            <!--                        <div class="ml-2">{{ currentLanguage }}</div>-->
            <!--                    </div>-->
            <!--                </ng-template>-->
            <!--                <ng-template let-language pTemplate="item">-->
            <!--                    <div class="country-item flex">-->
            <!--                        <img-->
            <!--                            alt="Flag image"-->
            <!--                            ngSrc="'assets/images/flag/flag_placeholder.png'"-->
            <!--                            [class]="'flag flag-' + language.flagCode.toLowerCase()"-->
            <!--                        />-->
            <!--                        <div class="ml-2">{{ language.name }}</div>-->
            <!--                    </div>-->
            <!--                </ng-template>-->
            <!--            </p-dropdown>-->
            <button (click)="logOut()" type="button" class="p-link mx-2 p-1 layout-top-bar-button w-full p-4 lg:p-1">
                <div class="flex align-items-center text-primary">
                    <i class="menu-icon pi fa-solid fa-sign-out text-primary"></i>
                    <div class="flex-grow-1 ml-1 ml-1 block lg:hidden xl:block">{{ 'button.logout' | translate }}</div>
                </div>
            </button>
        </div>
    </div>
</div>
