import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { NgIf, NgStyle, NgClass, AsyncPipe, LowerCasePipe } from '@angular/common';
import { Router } from '@angular/router';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Subject } from 'rxjs';
import { DividerModule } from 'primeng/divider';
import { ButtonModule } from 'primeng/button';
import { SharedModule } from 'primeng/api';
import { CarouselModule } from 'primeng/carousel';

import { AuthenticationService } from '../../../services/authentication.service';
import { BrandService } from '../../../../application/services/brand.service';
import { BrandSettings } from '../../../../../../common/models/brand-settings';
import { BrandUrlService } from 'src/frontend/app/application/services/brandurl.service';

@Component({
    selector: 'app-signup',
    templateUrl: './signup.component.html',
    styleUrls: ['./signup.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        CarouselModule,
        SharedModule,
        NgStyle,
        NgClass,
        ButtonModule,
        DividerModule,
        AsyncPipe,
        LowerCasePipe,
        TranslateModule,
    ],
})
export class SignupComponent implements OnInit, OnDestroy {
    valCheck: string[] = ['remember'];
    email!: string;
    password!: string;
    isDesktop = true;
    brandSettings$ = this.brandService.brandSettings$;
    loginBackgroundPhotos$ = this.brandService.loginBackgroundPhotos$;

    private onDestroy = new Subject<void>();

    constructor(
        public brandService: BrandService,
        private authenticationService: AuthenticationService,
        public router: Router,
        private oidcSecurityService: OidcSecurityService,
        private translateService: TranslateService,
        private brandUrlService: BrandUrlService
    ) {}

    ngOnInit() {
        this.checkScreenSize();

        this.oidcSecurityService.isAuthenticated$.subscribe(({ isAuthenticated }) => {
            if (!isAuthenticated) {
                return;
            }

            this.router.navigate(['/']).then();
        });
    }

    ngOnDestroy() {
        this.onDestroy.next();
        this.onDestroy.complete();
    }

    @HostListener('window:resize', ['$event'])
    onResize() {
        this.checkScreenSize();
    }

    logIn() {
        this.authenticationService.login();
    }

    register(requireVIN: boolean) {
        this.authenticationService.register(requireVIN);
    }

    checkScreenSize(): void {
        this.isDesktop = window.innerWidth > 576;
    }

    brandLogoLight(brandSettings: BrandSettings): string {
        if (!brandSettings.brandLogoLight || !brandSettings.brandLogoLight[0]) {
            return '';
        }

        return `${this.backendUrl}${brandSettings.brandLogoLight[0].url}`;
    }

    get brandLogoAlt(): string {
        return this.translateService.instant('brandLogoAlt', this.brandService.brandNameString);
    }

    get backendUrl(): string {
        return this.brandUrlService.brandBackendUrl;
    }
}
