<ng-container>
    <div *ngIf="root && item.visible !== false" class="layout-menuitem-root-text">{{ item.label!! | translate }}</div>
    <a
        *ngIf="(!item.routerLink || item.items) && item.visible !== false"
        [attr.href]="item.url"
        (click)="itemClick($event)"
        [ngClass]="item.styleClass"
        [attr.target]="item.target"
        tabindex="0"
        pRipple
    >
        <ng-container *ngIf="item.icon">
            <i class="layout-menuitem-icon pi" [ngClass]="item.icon"></i>
        </ng-container>
        <span class="layout-menuitem-text">{{ item.label!! | translate }}</span>
        <i class="layout-submenu-toggler pi fa-solid fa-chevron-down" *ngIf="item.items" [ngClass]="item.icon"></i>
    </a>
    <a
        *ngIf="item.routerLink && !item.items && item.visible !== false"
        (click)="itemClick($event)"
        [ngClass]="item.styleClass"
        [routerLink]="item.routerLink"
        routerLinkActive="active-route"
        [routerLinkActiveOptions]="
            item.routerLinkActiveOptions || {
                paths: 'exact',
                queryParams: 'ignored',
                matrixParams: 'ignored',
                fragment: 'ignored'
            }
        "
        [fragment]="item.fragment"
        [queryParamsHandling]="item.queryParamsHandling"
        [preserveFragment]="item.preserveFragment"
        [skipLocationChange]="item.skipLocationChange"
        [replaceUrl]="item.replaceUrl"
        [state]="item.state"
        [queryParams]="item.queryParams"
        [attr.target]="item.target"
        tabindex="0"
        pRipple
    >
        <ng-container *ngIf="item.icon">
            <i class="layout-menuitem-icon pi" [ngClass]="item.icon"></i>
        </ng-container>
        <span class="layout-menuitem-text">{{ item.label!! | translate }}</span>
        <i class="layout-submenu-toggler pi fa-solid fa-chevron-down" *ngIf="item.items" [ngClass]="item.icon"></i>
    </a>

    <ul *ngIf="item.items && item.visible !== false" [@children]="submenuAnimation">
        <ng-template ngFor let-child let-i="index" [ngForOf]="item.items">
            <li app-menuitem [item]="child" [index]="i" [parentKey]="key" [class]="child.badgeStyleClass"></li>
        </ng-template>
    </ul>
</ng-container>
