import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ContentService } from './content.service';
import { MemberDetails } from '../../../../common/models/MemberDetails';
import { DealerAndCarModel } from '../models/user-profile-form/DealerAndCarModel';
import { HttpWrapperService } from './http-wrapper.service';
import { CarDetails } from 'src/common/models/CarDetails';

@Injectable({
    providedIn: 'root',
})
export class MemberService {
    memberDetails$ = new Observable<MemberDetails>();
    constructor(
        private httpWrapper: HttpWrapperService,
        private contentService: ContentService,
    ) {}

    getUserDetails(brandKey: string, userKey: string): Observable<MemberDetails> {
        const params = {
          brandKey: brandKey,
          userKey: userKey,
        };
    
        const url = `${this.contentService.url}umbraco/api/MemberApi/GetMember`; 
        return this.httpWrapper.get<MemberDetails>(url, params);
      }
    
      updateUserDetails(memberDetails: MemberDetails): Observable<MemberDetails> {
        const url = `${this.contentService.url}umbraco/api/MemberApi/UpdateMember`;
        return this.httpWrapper.post<MemberDetails>(url, memberDetails);
      }
    
      getOwnershipTypes(): Observable<string[]> {
        const url = `${this.contentService.url}umbraco/api/MemberApi/GetOwnershipTypes`;
        return this.httpWrapper.get<string[]>(url);
      }
    
      updateDealerAndCarDetails(dealerAndCarModel: DealerAndCarModel): Observable<DealerAndCarModel> {
        const url = `${this.contentService.url}umbraco/api/MemberApi/UpdateDealerAndCarDetails`;
        return this.httpWrapper.post<DealerAndCarModel>(url, dealerAndCarModel);
      }

      removeCarOwnership(memberKey: string, brandKey: string, carId: string): Observable<void> {
        const params = {
          memberKey: memberKey,
          brandKey: brandKey,
          carId: carId,
        };
        const url = `${this.contentService.url}umbraco/api/MemberApi/RemoveCarOwnershipFromMember`;
        return this.httpWrapper.delete<void>(url, params);
    }

    getCarByVinOrRegistrationNumber(memberDetails: MemberDetails, vinOrRegistrationNumber: string): Observable<CarDetails> {
      const url = `${this.contentService.url}umbraco/api/MemberApi/FindAndAssignCarToMember`;
      const requestBody = {
        memberDetails: memberDetails,
        vinOrRegistrationNumber: vinOrRegistrationNumber
      };
      return this.httpWrapper.post<CarDetails>(url, requestBody);
  }
}
