export const webManiFest = {
    name: 'BRAND_NAME-kaista-app',
    short_name: 'BRAND_NAME-kaista',
    theme_color: '#ffffff',
    background_color: '#002c5f',
    display: 'standalone',
    scope: 'BASE_URL/',
    start_url: 'BASE_URL/',
    icons: [
        {
            src: 'BASE_URL/assets/icons/BRAND_NAME-icon-72x72.png',
            sizes: '72x72',
            type: 'image/png',
        },
        {
            src: 'BASE_URL/assets/icons/BRAND_NAME-icon-96x96.png',
            sizes: '96x96',
            type: 'image/png',
        },
        {
            src: 'BASE_URL/assets/icons/BRAND_NAME-icon-128x128.png',
            sizes: '128x128',
            type: 'image/png',
        },
        {
            src: 'BASE_URL/assets/icons/BRAND_NAME-icon-144x144.png',
            sizes: '144x144',
            type: 'image/png',
        },
        {
            src: 'BASE_URL/assets/icons/BRAND_NAME-icon-152x152.png',
            sizes: '152x152',
            type: 'image/png',
        },
        {
            src: 'BASE_URL/assets/icons/BRAND_NAME-android-chrome-192x192.png',
            sizes: '192x192',
            type: 'image/png',
        },
        {
            src: 'BASE_URL/assets/icons/BRAND_NAME-icon-384x384.png',
            sizes: '384x384',
            type: 'image/png',
        },
        {
            src: 'BASE_URL/assets/icons/BRAND_NAME-android-chrome-512x512.png',
            sizes: '512x512',
            type: 'image/png',
        },
    ],
};
