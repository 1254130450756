export const PAGE_SIZE = 15;
export const MAX_INT32 = 2147483647;

export const ERROR_API_CALL_FAILED = 'error.api.call.failed';
export const ERROR_NOT_FOUND = 'error.not.found';
export const ERROR_UNAUTHORIZED = 'error.unauthorized';
export const ERROR_FORBIDDEN = 'error.forbidden';
export const ERROR_SERVER_ERROR = 'error.server.error';
export const ERROR_UNPROCESSABLE_ENTITY = 'error.server.unprocessable.entity';
export const ERROR_INVALID_REQUEST = 'error.invalid.request';

export const DB_ERP = 'erp';

export const P_SORT_FIELD = 'sortField';
export const P_SORT_ORDER = 'sortOrder';

export const P_SORT_FIELDS = 'sortFields';
export const P_SORT_ORDERS = 'sortOrders';

export const P_TEXT_SEARCH_FIELDS = 'textSearchFields';
export const P_TEXT_SEARCH_PATTERN = 'textSearchPatterns';
export const P_QUERY = 'query';
export const P_PAGE_SIZE = 'pageSize';
export const P_PAGE = 'page';

export const DEFAULT_GROUP_SPACE_MANAGERS = 'space-managers';
export const DEFAULT_GROUP_SPACE_EDITORS = 'space-editors';
export const DEFAULT_GROUP_SPACE_VIEWERS = 'space-viewers';

export const LATEST_NEWS_URL = 'articles/';
export const EXTERNAL_URL = 'External';
