import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardModule } from 'primeng/card';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { MemberDetails } from 'src/common/models/MemberDetails';
import { TranslateModule } from '@ngx-translate/core';
import { BrandService } from '../../services/brand.service';
import { MemberService } from '../../services/member.service';

@Component({
    standalone: true,
    imports: [CommonModule, CardModule, TranslateModule],
    selector: 'app-member-info',
    templateUrl: './member-info.component.html',
})
export class MemberInfoComponent implements OnInit {
    memberDetails: MemberDetails | null = null;

    constructor(
        private brandService: BrandService,   
        private oidcSecurityService: OidcSecurityService,        
        private memberService: MemberService,
    ) {}
    
    get brandNameString(): string {
        return this.brandService.brandNameString;
    }

    ngOnInit() {
        this.brandService.brandId$.subscribe({
            next: (brandId) => {
                if (brandId.length) {

                    this.oidcSecurityService.userData$.subscribe({
                        next: (data) => {
                            const userKey = data.userData.sub;
                            this.memberService.getUserDetails(brandId, userKey).subscribe({
                                next: (data: MemberDetails) => {
                                    this.memberDetails = data;
                                },
                            });
                        },
                    });
                }
            },
        });
    }
}
