<div class="field">
    <label [htmlFor]="name" [id]="name">{{ getFieldLabel() }}</label>
    <input
        pInputText
        [attr.aria-label]="name"
        type="email"
        [readonly]="readonly"
        [style]="{ height: '40px', width: width ? 'width: ' + width + 'px;' : '100%' }"
        [formControl]="formControl"
    />
    <app-validation-errors [control]="formControl"></app-validation-errors>
</div>
