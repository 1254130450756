import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AuthenticatedGuard {
    constructor(
        private oidcSecurityService: OidcSecurityService,
        private router: Router,
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
        return this.oidcSecurityService.isAuthenticated$.pipe(
            take(1),
            map(({ isAuthenticated }) => {
                if (isAuthenticated) {
                    return true;
                }

                // redirect if not authenticated
                return this.router.parseUrl('/signup');
            }),
        );
    }
}
