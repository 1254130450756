import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-validation-errors',
    templateUrl: './validation-errors.component.html',
    styleUrls: ['./validation-errors.component.scss'],
    standalone: true,
    imports: [NgIf, TranslateModule],
})
export class ValidationErrorsComponent {
    @Input() control!: FormControl;
}
