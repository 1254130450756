<div class="grid" *ngIf="dashboard">
    <div class="col-12">
        <app-page-header
            *ngIf="dashboard!.pageHeaderModel"
            [pageHeaderModel]="dashboard!.pageHeaderModel"
        ></app-page-header>
    </div>
    <div class="col-12 lg:col-6 flex flex-column">
        <div class="flex flex-column gap-2">
            <ng-container *ngFor="let memberCar of memberCars!">
                <app-my-vehicle-card [memberCar]="memberCar"></app-my-vehicle-card>
            </ng-container>
            <div class="mb-6" *ngIf="isHyundaiBrand">
                <p-accordion class="w-full" [activeIndex]="-1">
                    <p-accordionTab>
                        <ng-template pTemplate="header">
                            <div class="flex align-items-center justify-content-between bg-blue-50">
                                <span class="vertical-align-middle">{{
                                    'Electric Service Book Title' | translate
                                }}</span>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="content">
                            <ng-container *ngFor="let memberCar of memberCars">
                                <div class="mb-2">
                                    <p-button
                                        label="{{ 'Fetch Service e-book for' | translate }} {{ memberCar.model }}"
                                        icon="pi fa-solid fa-angle-right"
                                        iconPos="right"
                                        (onClick)="fetchServiceEbook(memberCar.vin)"
                                        class="w-full"
                                    ></p-button>
                                </div>
                            </ng-container>
                        </ng-template>
                    </p-accordionTab>
                </p-accordion>
            </div>
            <!-- <div class="mb-6"> TODO in next phase
                <p-accordion class="w-full" [activeIndex]="0">
                    <p-accordionTab>
                        <ng-template pTemplate="header">
                            <div class="flex align-items-center justify-content-between bg-blue-50">
                                <span class="vertical-align-middle">{{ 'title.guarantee' | translate }}</span>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="content">
                            <p-button
                                label="{{ 'button.readMoreAboutGuarantee' | translate }}"
                                icon="pi fa-solid fa-angle-right"
                                iconPos="right"
                            ></p-button>
                        </ng-template>
                    </p-accordionTab>
                </p-accordion>
            </div> -->
            <div class="mb-6">
                <div class="flex flex-wrap">
                    <ng-container *ngFor="let quickLink of dashboard!.quickCardLinks">
                        <app-quick-link-card class="col-12 lg:col-6 p-3" [quickLink]="quickLink"></app-quick-link-card>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>

    <div class="col-12 lg:col-6">
        <div class="mb-6">
            <app-latest-news></app-latest-news>
        </div>
        <div class="mb-6">
            <app-product-carousel
                *ngIf="dashboard!.carouselProducts"
                [carouselProducts]="dashboard!.carouselProducts"
                [carouselAutorotate]="dashboard!.carouselAutorotate"
            ></app-product-carousel>
        </div>
        <div class="bg-gray-200">
            {{ car | json }}
        </div>
    </div>
</div>
