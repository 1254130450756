<div class="grid">
    <div class="col-12">
        <app-page-header *ngIf="pageHeaderModel" [pageHeaderModel]="pageHeaderModel"></app-page-header>
    </div>
    <div class="col-12 lg:col-6">
        <app-member-info></app-member-info>
    </div>
    <div class="col-12 lg:col-6">
        <app-book-service-form></app-book-service-form>
    </div>
</div>
