import { Component, Input, OnInit } from '@angular/core';
import { BrandService } from '../../../services/brand.service';
import { HelperService } from '../../../../framework/services/helper.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NgClass, LowerCasePipe } from '@angular/common';
import { CarDetails } from '../../../../../../common/models/CarDetails';
import { UmbracoTranslateLoader } from '../../../umbraco-translate-loader';

@Component({
    selector: 'app-my-vehicle-card',
    templateUrl: './my-vehicle-card.component.html',
    standalone: true,
    imports: [NgClass, LowerCasePipe, TranslateModule],
})
export class MyVehicleCardComponent implements OnInit {
    @Input({ required: true }) memberCar!: CarDetails;
    formattedDate: string = '';
    translations: any = {};

    constructor(
        public brandService: BrandService,
        public helperService: HelperService,
        private translateLoader: UmbracoTranslateLoader,
        private translateService: TranslateService
    ) {}

    get brandNameString(): string {
        return this.brandService.brandNameString;
    }

    ngOnInit() {
        this.formattedDate = this.helperService.formatDate(new Date(this.memberCar.takenToUseDate));
        this.loadTranslations();
    }

    private loadTranslations() {
        const isoCode =  this.translateService.currentLang || this.translateService.defaultLang;
        this.translateLoader.getTranslation(isoCode).subscribe({
            next: (translations) => {
                this.translations = translations;
                this.applyTranslations();
            },
            error: (error) => {
                console.error('Error loading translations', error);
            },
        });
    }

    private applyTranslations() {
        this.memberCar.ownershipType = this.mapOwnershipType(this.memberCar.ownershipType);
    }

    private mapOwnershipType(ownershipType: string): string {
        if (ownershipType === 'Unknown') {
            return this.translations['Ownership Type.Unknown'];
        }

        if (this.translations[ownershipType]) {
            return this.translations[ownershipType];
        }
        return ownershipType;
    }
}
