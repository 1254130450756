import { Component } from '@angular/core';
import { BookServiceComponent } from '../book-service/book-service.component';

@Component({
    selector: 'app-service',
    standalone: true,
    imports: [BookServiceComponent],
    templateUrl: './service.component.html',
    styleUrl: './service.component.scss',
})
export class ServiceComponent {}
