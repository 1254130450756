import { Component, HostListener, OnInit, Input } from '@angular/core';
import { BrandService } from '../../services/brand.service';
import { CarouselProduct } from '../../models/CarouselProduct';
import { SharedModule } from 'primeng/api';
import { CarouselModule } from 'primeng/carousel';
import { NgIf, NgStyle } from '@angular/common';
import { ContentService } from '../../services/content.service';

@Component({
    selector: 'app-product-carousel',
    templateUrl: './product-carousel.component.html',
    standalone: true,
    imports: [NgIf, CarouselModule, SharedModule, NgStyle],
})
export class ProductCarouselComponent implements OnInit {
    @Input({ required: true }) carouselProducts!: CarouselProduct[];
    @Input({ required: true }) carouselAutorotate!: boolean;
    isDesktop = true;

    constructor(
        public brandService: BrandService,
        public contentService: ContentService,
    ) {}

    ngOnInit() {
        this.checkScreenSize();
    }

    @HostListener('window:resize', ['$event'])
    onResize() {
        this.checkScreenSize();
    }

    checkScreenSize(): void {
        this.isDesktop = window.innerWidth > 576;
    }

    get bannerPhoto(): string {
        return this.brandService.bannerPhoto;
    }

    redirectToUrl(url: string, target: string): void {
        window.open(url, target.length ? target : '_self');
    }
}
