<div class="card p-0 border-0 shadow-2">
    <div class="p-card-body p-0">
        <div class="p-card-title flex align-items-center font-bold text-base py-2 px-4">
            <div class="col-4">
                <div [ngClass]="(brandNameString | lowercase) + '-text-color'">{{ 'Car Details' | translate }}</div>
            </div>
            <div class="col-4">
                <div [ngClass]="(brandNameString | lowercase) + '-text-color'">{{ memberCar.registrationNumber }}</div>
            </div>
            <div class="col-4">
                <div [ngClass]="(brandNameString | lowercase) + '-text-color'">
                    {{ memberCar.modelSerie }} {{ memberCar.model }}
                </div>
            </div>
        </div>
        <div class="p-card-content flex align-items-center p-4 border-top-2 border-primary">
            <div class="col-6 flex flex-column align-items-start gap-4">
                <div>
                    {{ 'Mileage' | translate }}
                </div>
                <div>
                    {{ 'Yearly Mileage' | translate }}
                </div>
                <div>
                    {{ 'Ownership' | translate }}
                </div>
                <div>
                    {{ 'Taken To Use Date' | translate }}
                </div>
            </div>
            <div class="col-6 flex flex-column align-items-center gap-4 font-semibold">
                <div>
                    {{ memberCar.mileage }}
                </div>
                <div>
                    {{ memberCar.yearlyMileage }}
                </div>
                <div>
                    {{ memberCar.ownershipType }}
                </div>
                <div>
                    {{ formattedDate }}
                </div>
            </div>
        </div>
    </div>
</div>
