import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardModule } from 'primeng/card';
import { ListboxModule } from 'primeng/listbox';
import { BrandService } from '../../../../../../frontend/app/application/services/brand.service';
import { ButtonModule } from 'primeng/button';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { ServiceUrgency } from '../../../../../../common/models/enumeration/service-urgency';
import { ContactMethod } from '../../../../../../common/models/enumeration/contact-method';
import { SelectFieldComponent } from '../../../../framework/components/fields/select-field/select-field.component';
import { TextAreaFieldComponent } from '../../../../framework/components/fields/text-area-field/text-area-field.component';
import { NumberFieldComponent } from '../../../../framework/components/fields/number-field/number-field.component';
import { DateFieldComponent } from '../../../../framework/components/fields/date-field/date-field.component';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { MemberService } from '../../../services/member.service';
import { BookServiceRequest } from '../../../../../../common/models/api/bookServiceRequest';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MemberDetails } from '../../../../../../common/models/MemberDetails';
import { CarDetails } from '../../../../../../common/models/CarDetails';
import { EmailFieldComponent } from '../../../../../../frontend/app/framework/components/fields/email-field/email-field.component';
import { MaintenanceService } from '../../../services/maintenance.service';
import { catchError, Subject, takeUntil } from 'rxjs';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { MemberInfoComponent } from '../../member-info/member-info.component';
import { DealerDetails } from '../../../models/DealerDetails';
import { AutoCompleteFieldComponent } from 'src/frontend/app/framework/components/fields/autoComplete-field/autoComplete-field.component';
import { ModelFilter } from '../../../models/ModelFilter';
import { Dealer } from '../../../models/Dealer';

@Component({
    selector: 'app-book-service-form',
    standalone: true,
    imports: [
        CommonModule,
        CardModule,
        ListboxModule,
        ButtonModule,
        AutoCompleteFieldComponent,
        MemberInfoComponent,
        ReactiveFormsModule,
        SelectFieldComponent,
        TextAreaFieldComponent,
        NumberFieldComponent,
        DateFieldComponent,
        EmailFieldComponent,
        ToastModule,
        TranslateModule,
    ],
    templateUrl: './book-service-form.component.html',
    providers: [MessageService],
})
export class BookServiceFormComponent implements OnInit {
    bookForm!: FormGroup;
    chosenServiceLocation!: DealerDetails;
    data: any = {};
    memberDetails: MemberDetails | null = null;
    memberCars: CarDetails[] = [];
    memberCarsRegistrationNumbers: Object[] = [];
    dealers: Dealer[] = [];
    filteredDealers: Dealer[] = [];
    urgencyOptions: { label: string; id: string }[] = [];
    contactMethodOptions: { label: string; id: string }[] = [];
    formSent: boolean = false;

    private destroy$ = new Subject<void>();

    constructor(
        private brandService: BrandService,
        private formBuilder: FormBuilder,
        private oidcSecurityService: OidcSecurityService,
        private memberService: MemberService,
        private translateService: TranslateService,
        private maintenanceService: MaintenanceService,
        private messageService: MessageService,
    ) {}

    get brandNameString(): string {
        return this.brandService.brandNameString;
    }

    ngOnInit(): void {
        this.initializeForm();
        this.loadTranslations();
        this.loadMemberDetails();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    searchDealers(query: string): void {
        const filters: ModelFilter = { serviceDealerName: query };
        this.maintenanceService
            .getDealers(this.brandNameString, 0, 90, filters)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (data: Dealer[]) => {
                    this.dealers = data;
                    this.filteredDealers = this.filterDealers(query);
                },
                error: (err) => this.handleError('Error fetching dealers', err),
            });
    }

    selectDealer(event: any): void {
        const dealerId = event.value.id;
        this.fetchDealerDetails(dealerId);
    }

    onSendClicked(): void {
        if (
            !this.chosenServiceLocation ||
            !this.chosenServiceLocation.id ||
            !this.chosenServiceLocation.displayName ||
            !this.bookForm.valid
        ) {
            this.messageService.add({
                severity: 'error',
                summary: this.translateService.instant('Error'),
                detail: this.translateService.instant('Please Fill All Required Fields Correctly In Request Form'),
            });
            return;
        }

        if (this.bookForm.valid) {
            const formData = this.bookForm.value;

            const serviceUrgency =
                this.urgencyOptions.find((option) => option.id === formData.serviceUrgency)?.label || '';
            const contactBy = this.contactMethodOptions.find((option) => option.id === formData.contactBy)?.label || '';

            const request: BookServiceRequest = {
                serviceRequestDetails: {
                    serviceProposedDate: formData.serviceProposedDate.toISOString().split('T')[0],
                    serviceUrgency: serviceUrgency,
                    mileage: formData.mileage,
                    contactBy: contactBy,
                    serviceRequestDescription: formData.serviceRequestDescription,
                    serviceEmailAddress: this.chosenServiceLocation.email,
                },
                brand: this.brandNameString,
                language: this.translateService.currentLang || this.translateService.defaultLang,
                email: this.memberDetails?.email || '',
                registrationNumber: formData.registrationNumber,
            };

            this.maintenanceService
                .sendServiceRequest(request)
                .pipe(
                    catchError((e) => {
                        this.onSendServiceRequestFailure();
                        throw new Error('Error occurred in sendServiceRequest', e);
                    }),
                    takeUntil(this.destroy$),
                )
                .subscribe({
                    next: (data) => {
                        if (data) {
                            this.onSendServiceRequestSuccess();
                            this.formSent = true;
                        }
                    },
                });
        } else {
            this.markFormFieldsAsTouched();
        }
    }

    private initializeForm(): void {
        this.bookForm = this.formBuilder.group({
            serviceProposedDate: new Date(),
            serviceUrgency: '',
            mileage: 0,
            contactBy: '',
            serviceRequestDescription: '',
            registrationNumber: '',
        });
        this.formSent = false;
    }

    private loadTranslations(): void {
        const isoCode = this.translateService.currentLang || this.translateService.defaultLang;
        this.translateService
            .getTranslation(isoCode)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (translations) => {
                    this.urgencyOptions = this.mapOptions(Object.values(ServiceUrgency), translations);
                    this.contactMethodOptions = this.mapOptions(Object.values(ContactMethod), translations);
                },
                error: (err) => this.handleError('Error loading translations', err),
            });
    }

    private mapOptions(options: string[], translations: any): { label: string; id: string }[] {
        return options.map((option) => ({
            label: translations[`${option}`] || option,
            id: option,
        }));
    }

    private loadMemberDetails(): void {
        this.brandService.brandId$.pipe(takeUntil(this.destroy$)).subscribe({
            next: (brandId) => {
                if (brandId.length) {
                    this.oidcSecurityService.userData$.pipe(takeUntil(this.destroy$)).subscribe({
                        next: (data) => {
                            const userKey = data.userData.sub;
                            this.memberService
                                .getUserDetails(brandId, userKey)
                                .pipe(takeUntil(this.destroy$))
                                .subscribe({
                                    next: (data: MemberDetails) => {
                                        this.memberDetails = data;
                                        this.memberCars = data.memberCars || [];
                                        this.setMemberCarsRegistrationNumbers();
                                        this.setChosenServiceLocation();
                                    },
                                });
                        },
                    });
                }
            },
        });
    }

    private setMemberCarsRegistrationNumbers(): void {
        this.memberCarsRegistrationNumbers = this.memberCars.map((car: CarDetails) => ({
            id: car.registrationNumber,
            label: car.registrationNumber,
        }));
    }

    private setChosenServiceLocation(): void {
        if (this.memberCars.length) {
            const dealerCode = this.memberCars[0].dealerCode;
            if (dealerCode) {
                this.fetchDealerDetails(dealerCode);
            }
        }
    }

    private fetchDealerDetails(dealerId: number): void {
        this.maintenanceService
            .getDealerDetails(this.brandNameString, dealerId)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (data: DealerDetails) => {
                    this.chosenServiceLocation = data;
                    const searchField = this.chosenServiceLocation.label ?? this.chosenServiceLocation.dealerName;
                    this.chosenServiceLocation.displayName = searchField;
                },
                error: (err) => this.handleError('Error fetching dealer details', err),
            });
    }

    private filterDealers(query: string): Dealer[] {
        return this.dealers.filter((dealer) => {
            let searchField = dealer.label ?? dealer.dealerName;

            dealer.displayName = searchField;
            return searchField.toLowerCase().includes(query.toLowerCase());
        });
    }

    private handleError(message: string, error: any): void {
        console.error(message, error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: message });
    }

    private markFormFieldsAsTouched(): void {
        Object.keys(this.bookForm.controls).forEach((field) => {
            const control = this.bookForm.get(field);
            if (control) {
                control.markAsTouched();
            }
        });
    }

    private onSendServiceRequestSuccess(): void {
        this.messageService.add({
            severity: 'success',
            summary: this.translateService.instant('Success'),
            detail: this.translateService.instant('Service Request Sent Successfully'),
        });
    }

    private onSendServiceRequestFailure(): void {
        this.messageService.add({
            severity: 'error',
            summary: this.translateService.instant('Error'),
            detail: this.translateService.instant('Service Request Sent Failed'),
        });
    }
}
