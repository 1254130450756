<div class="field">
    <label [htmlFor]="name" [id]="name">{{ getFieldLabel() }}</label>
    <p-inputNumber class="w-full"
        [attr.aria-label]="name"
        [inputId]="name"
        [formControl]="formControl"
        [style]="{ height: '40px', width: width ? 'width: ' + width + 'px;' : '100%' }"
    ></p-inputNumber>
    <app-validation-errors [control]="formControl"></app-validation-errors>
</div>
