import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CommonModule } from '@angular/common';
import { DropdownModule } from 'primeng/dropdown';
import { ValidationErrorsComponent } from '../validation-errors/validation-errors.component';

@Component({
    selector: 'app-auto-complete-field',
    templateUrl: './autoComplete-field.component.html',
    styleUrls: ['./autoComplete-field.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AutoCompleteFieldComponent),
            multi: true,
        },
    ],
    standalone: true,
    imports: [ DropdownModule, CommonModule, FormsModule, ReactiveFormsModule, AutoCompleteModule, ValidationErrorsComponent],
})
export class AutoCompleteFieldComponent implements OnInit {
    @Input() name!: string;
    @Input() placeholder!: string;
    @Input() suggestions!: any[];
    @Input() field!: string;
    @Input() width!: number;
    @Input() readonly = false;
    @Input() dropdown = true;
    
    @Output() completeMethod: EventEmitter<any> = new EventEmitter<any>();
    @Output() onSelect: EventEmitter<any> = new EventEmitter<any>();
    
    formControl: FormControl = new FormControl();
    filteredSuggestions: any[] = [];

    constructor() {}

    ngOnInit() {
        if (this.readonly) {
            this.formControl.disable();
        }
    }

    writeValue(value: any) {
        this.formControl.setValue(value);
    }

    registerOnChange(fn: Function) {
        this.formControl.valueChanges.subscribe((val) => fn(val));
    }

    registerOnTouched(fn: Function) {
        this.formControl.valueChanges.subscribe((val) => fn(val));
    }

    search(event: any) {
        this.completeMethod.emit(event);
    }

    select(event: any) {
        this.onSelect.emit(event);
    }
}