<ng-container *ngIf="control.invalid && (control.dirty || control.touched)">
    <small *ngIf="control.errors?.['required']" class="text-red-500">{{ 'label.fieldIsRequired' | translate }}</small>
    <small *ngIf="control.errors?.['minlength']" class="text-red-500">{{
        'label.minLength' | translate: { minLength: control.errors?.['minlength']['requiredLength'] }
    }}</small>
    <small *ngIf="control.errors?.['maxlength']" class="text-red-500">{{
        'label.maxLength' | translate: { maxLength: control.errors?.['maxlength']['requiredLength'] }
    }}</small>
    <small *ngIf="control.errors?.['email']" class="text-red-500">{{ 'label.emailIsInvalidFormat' | translate }}</small>
    <small *ngIf="control.errors?.['customPattern']?.length > 0" class="text-red-500">{{
        control.errors?.['customPattern']
    }}</small>
</ng-container>
