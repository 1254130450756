import { DateTime } from 'luxon';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { catchError, Observable, throwError } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class HelperService {
    constructor(private translateService: TranslateService, private messageService: MessageService) {}

    formatCurrency(value: number | null | undefined) {
        if (typeof value === 'undefined' || value === null) {
            return '-';
        } else {
            return new Intl.NumberFormat('fi-FI', { style: 'currency', currency: 'EUR' }).format(value);
        }
    }

    formatNumber(value: number | null | undefined) {
        if (typeof value === 'undefined' || value === null) {
            return '-';
        } else {
            return new Intl.NumberFormat('fi-FI').format(value);
        }
    }

    formatDate(value: Date | string | null | undefined): string {
        if (!value) {
            return '-';
        }

        let dateObj: Date;
        if (typeof value === 'string') {
            dateObj = new Date(value);
        } else {
            dateObj = value;
        }

        if (isNaN(dateObj.getTime())) {
            return '-';
        }

        return new Intl.DateTimeFormat(this.translateService.currentLang, {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        }).format(dateObj);
    }

    sortAlphabetically(sortProperty: string) {
        return (itemA: any, itemB: any) => {
            const nameA = itemA[sortProperty].toLowerCase();
            const nameB = itemB[sortProperty].toLowerCase();

            return nameA.localeCompare(nameB);
        };
    }

    convertLocalDateToUtc(date: Date): Date {
        const utcOffset = DateTime.fromJSDate(date).offset;
        const utcStartOfDay = DateTime.fromJSDate(date, { zone: 'UTC' }).plus({ minutes: utcOffset }).startOf('day');

        return utcStartOfDay.toJSDate();
    }

    showMessage(
        severity: 'success' | 'info' | 'warn' | 'error',
        summaryKey: string,
        detailKey: string
      ): void {
        this.messageService.add({
          severity,
          summary: this.translateService.instant(summaryKey),
          detail: this.translateService.instant(detailKey),
        });
      }

   handleObservableError<T>(operation = 'operation') {
        return (source: Observable<T>) =>
          source.pipe(
            catchError((error: any) => {
              console.error(`${operation} failed`, error);
              return throwError(() => error);
            }),
          );
      }

    get currentLanguage(): string {
        return this.translateService.currentLang || this.translateService.defaultLang;
    }
}
