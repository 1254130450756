<div class="field flex">
    <div
        class="flex gap-2"
        [ngClass]="labelTop ? 'flex-column' : 'flex-row-reverse align-items-center justify-content-left'"
    >
        <label [htmlFor]="name" [id]="name">{{ getFieldLabel() }}</label>
        <p-inputSwitch [attr.aria-label]="name" [formControl]="formControl"></p-inputSwitch>
    </div>
    <app-validation-errors [control]="formControl"></app-validation-errors>
</div>
