<div class="field">
    <p-autoComplete
        [style]="{ height: '40px', width: width ? width + 'px' : '100%'}"
        [formControl]="formControl"
        [suggestions]="suggestions"
        (completeMethod)="search($event)"
        (onSelect)="select($event)"
        [field]="field"
        [dropdown]="dropdown"
        [disabled]="readonly"
        [placeholder]="placeholder">
    </p-autoComplete>
    <app-validation-errors [control]="formControl"></app-validation-errors>
</div>