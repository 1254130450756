<div class="grid" *ngIf="article">
    <div class="col-12" *ngIf="article.mainImage">
        <img class="border-round w-full" [src]="article.mainImage.url" alt="article.mainImage.altText" />
    </div>
    <div class="col-12">
        <p [ngClass]="(brandNameString | lowercase) + '-text-color'" class="font-bold uppercase">
            {{ article.category }} {{ article.publishDate }}
        </p>
    </div>
    <h4 [ngClass]="(brandNameString | lowercase) + '-text-color'" class="font-bold uppercase pl-1">
        {{ article.title }}
    </h4>
    <div class="col-12 text-xl" [innerHtml]="article.articleBody"></div>
</div>
