<div
    class="relative min-h-screen min-w-screen overflow-y-auto"
    *ngIf="loginBackgroundPhotos$ | async as backgroundImages"
>
    <div class="fixed top-0 left-0 w-full h-full z-0">
        <p-carousel
            *ngIf="isDesktop"
            [showNavigators]="false"
            [value]="backgroundImages"
            [numVisible]="1"
            [numScroll]="1"
            [circular]="true"
            [autoplayInterval]="5000"
            indicatorsContentClass="indicatorsContentClass"
        >
            <ng-template let-image pTemplate="item">
                <div
                    [ngStyle]="{ 'background-image': 'url(' + backendUrl + image + ')' }"
                    class="login-background-image"
                ></div>
            </ng-template>
        </p-carousel>
    </div>
    <div class="relative w-full h-full z-1" *ngIf="brandSettings$ | async as brandSettings">
        <div class="relative card auth-card sm:auth-card block p-0">
            <div
                class="card-header flex justify-content-center flex-column p-4 gap-4 auth-card-header sm:auth-card-header"
                [ngClass]="'auth-card-header-' + (brandSettings.brand[0].name | lowercase)"
            >
                <div class="flex flex-row">
                    <div class="col-6 align-items-center justify-content-left text-center image-container">
                        <img
                            class="image-logo"
                            [src]="brandLogoLight(brandSettings)"
                            alt="{{ 'brandLogoAlt' | translate: { brandName: brandSettings.brand[0].name } }}"
                        />
                    </div>
                    <div class="col-6 align-items-center justify-content-right text-center my-auto">
                        <div class="text-3xl text-white font-medium">{{ brandSettings.brandAppName }}</div>
                    </div>
                </div>
                <p class="text-gray-300">
                    {{ brandSettings.brandLoginMainDescription }}
                    <b class="text-white">{{ brandSettings.brandLoginAdditionalDescription }}</b>
                </p>
            </div>
            <div
                class="flex flex-column align-items-center justify-content-start gap-2 bg-white w-full pt-6 auth-card-body sm:auth-card-body"
                [ngClass]="'color-' + (brandSettings.brand[0].name | lowercase)"
            >
                <div class="text-2xl w-10 font-bold mb-2">{{ brandSettings.brandWelcomeMessage }}</div>
                <div class="text-sm w-10 mb-2">{{ 'Log In With Chosen Method' | translate }}</div>
                <div class="w-10 mb-1">
                    <p-button
                        label="{{ 'Log in' | translate }}"
                        styleClass="p-button-outlined w-full"
                        icon="pi fa-solid fa-right-to-bracket"
                        (click)="logIn()"
                    >
                    </p-button>
                </div>
                <p-divider class="w-10 mb-2" align="center">
                    <span class="ml-4 mr-4 uppercase">{{ 'Or' | translate }}</span>
                </p-divider>
                <div class="w-10 mb-1">
                    <p-button
                        label="{{ 'Register With License Plate' | translate }}"
                        styleClass="p-button-outlined w-full"
                        icon="pi fa-solid fa-right-to-bracket"
                        (click)="register(false)"
                    >
                    </p-button>
                </div>
                <div class="w-10 mb-2">
                    <p-button
                        label="{{ 'Register With Vin' | translate }}"
                        styleClass="p-button-outlined w-full"
                        icon="pi fa-solid fa-right-to-bracket"
                        (click)="register(true)"
                    >
                    </p-button>
                </div>
            </div>
            <div
                class="flex flex-column align-items-center justify-content-center gap-2 bg-white cursor-pointer auth-card-footer"
                [ngClass]="'color-' + (brandSettings.brand[0].name | lowercase)"
            >
                <a *ngIf="brandSettings$ | async as brandSettings" [href]="brandSettings.contactUsPageUrl" target="_blank" class="align-self-center font-bold text-center uppercase">
                    {{ 'Contact us' | translate }}
                </a>
            </div>
        </div>
    </div>
</div>
