import { Injectable } from '@angular/core';
import { BrandUrlService } from '../../application/services/brandurl.service';

@Injectable({
  providedIn: 'root',
})
export class AuthConfigService {
    private _authority: string | undefined;
  
    constructor(private brandUrlService: BrandUrlService) {}
  
    loadAuthority(): string {
      this._authority = `${this.brandUrlService.brandBackendUrl}/.well-known/openid-configuration`;
      return this._authority;
    }
  
    get authority(): string | undefined {
      if (!this._authority) {
        this.loadAuthority();
      }
      return this._authority;
    }
  }